/**
 * @file: index.js
 * @author: eric <eric.blueplus@gmail.com>
 * @copyright: (c) 2015-2017 beego co., ltd
 */

import Swiper from 'swiper';
const w: any = window;

import '../assets/scss/index.scss';

export function init() {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  if (w.Swiper) {
    const swiper = new Swiper('.swiper-container', {
      // Optional parameters
      direction: 'horizontal',
      loop: true,
      autoplay: { delay: 3000 },

      // If we need pagination
      pagination: {
        el: '.swiper-pagination',
      },
    });
    console.log('swiper created', swiper);
  }
}

window.addEventListener('DOMContentLoaded', () => {
  init();
});
